import request from '@/utils/request'


// 查询上架版本控制列表
export function listVersion(query) {
  return request({
    url: '/grounding/version/list',
    method: 'get',
    params: query
  })
}

// 查询上架版本控制分页
export function pageVersion(query) {
  return request({
    url: '/grounding/version/page',
    method: 'get',
    params: query
  })
}

// 查询上架版本控制详细
export function getVersion(data) {
  return request({
    url: '/grounding/version/detail',
    method: 'get',
    params: data
  })
}

// 新增上架版本控制
export function addVersion(data) {
  return request({
    url: '/grounding/version/add',
    method: 'post',
    data: data
  })
}

// 修改上架版本控制
export function updateVersion(data) {
  return request({
    url: '/grounding/version/edit',
    method: 'post',
    data: data
  })
}

// 删除上架版本控制
export function delVersion(data) {
  return request({
    url: '/grounding/version/delete',
    method: 'post',
    data: data
  })
}
