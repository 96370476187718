<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @cancel="onClose" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :label-col="labelCol" :wrapper-col="wrapperCol" :model="form"  :rules="rules">
<!--      <a-form-model-item prop="type" >
        <span slot="label" >
          <a-tooltip>
            {{$t('上架版本控制.1:基础版  2:内测版  3:公测版  4:终测版   5:正式版')}}<template slot="title">{{$t('上架版本控制.1:基础版  2:内测版  3:公测版  4:终测版   5:正式版')}}</template>
          </a-tooltip>
        </span>
      </a-form-model-item>-->
      <a-form-model-item prop="type" >
        <span slot="label" >
          <a-tooltip>
            {{$t('版本升级.上架类型')}}<template slot="title">{{$t('版本升级.上架类型')}}</template>
          </a-tooltip>
        </span>
        <a-radio-group v-model="form.appType"  button-style="solid">
          <a-radio-button v-for="(item, index) in this.customDict.groundingTypeEnum" :value="item.type" :key="index">
            {{ item.name }}
          </a-radio-button>
        </a-radio-group>
      </a-form-model-item>
<!--      <a-form-model-item prop="name" >
        <span slot="label" >
          <a-tooltip>
            {{$t('上架版本控制.base:基础版  alpha:内测版  beta:公测版  rc:终测版   release:正式版')}}<template slot="title">{{$t('上架版本控制.base:基础版  alpha:内测版  beta:公测版  rc:终测版   release:正式版')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.name" :maxLength="50" :placeholder="$t('通用.输入.请输入')+$t('上架版本控制.base:基础版  alpha:内测版  beta:公测版  rc:终测版   release:正式版')" />
      </a-form-model-item>
      <a-form-model-item prop="content" >
        <span slot="label" >
          <a-tooltip>
            {{$t('上架版本控制.本次产品更新内容')}}<template slot="title">{{$t('上架版本控制.本次产品更新内容')}}</template>
          </a-tooltip>
        </span>
        <editor v-model="form.content" />
      </a-form-model-item>
      <a-form-model-item prop="appType" >
        <span slot="label" >
          <a-tooltip>
            {{$t('上架版本控制.APP类型 : 0安卓 1苹果 2通用')}}<template slot="title">{{$t('上架版本控制.APP类型 : 0安卓 1苹果 2通用')}}</template>
          </a-tooltip>
        </span>
      </a-form-model-item>
      <a-form-model-item prop="downloadUrl" >
        <span slot="label" >
          <a-tooltip>
            {{$t('上架版本控制.下载地址')}}<template slot="title">{{$t('上架版本控制.下载地址')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.downloadUrl" :maxLength="255" :placeholder="$t('通用.输入.请输入')+$t('上架版本控制.下载地址')" />
      </a-form-model-item>
      <a-form-model-item prop="remark" >
        <span slot="label" >
          <a-tooltip>
            {{$t('上架版本控制.备注')}}<template slot="title">{{$t('上架版本控制.备注')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.remark" :placeholder="$t('通用.输入.请输入')" type="textarea" allow-clear />
      </a-form-model-item>
      <a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">
        <img alt="example" style="width: 100%" :src="previewUrl" />
      </a-modal>
      <a-modal :visible="previewVideoVisible" :footer="null" @cancel="previewVideoCancel">
        <video style="width: 100%" :src="previewUrl" ref="myVideo"
               :poster="previewUrl + '?x-oss-process=video/snapshot,t_0,f_jpg'" :controls="true"></video>
      </a-modal>-->
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            {{$t('通用.按钮.保存')}}
          </a-button>
          <a-button type="dashed" @click="cancel">
            {{$t('通用.按钮.取消')}}
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getVersion, addVersion, updateVersion } from '@/api/grounding/version'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";
import {generateFilePath, uploadObject} from "@/api/tool/oss";

import Editor from '@/components/Editor'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    CustomDictTag,
    Editor
  },
  data () {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      submitLoading: false,
      formTitle: '',
      previewImageVisible: false,
      previewUrl: '',
      previewVideoVisible: false,
      loading: '',
      // 表单参数
      form: {
        id: null,

        type: 1,

        name: null,

        content: null,

        appType: null,

        downloadUrl: null,

        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        appType: [
          { required: true, message: this.$t('上架版本控制.APP类型 : 0安卓 1苹果 2通用')+this.$t('通用.文本.不能为空'), trigger: 'change' }
        ],
        downloadUrl: [
          { required: true, message: this.$t('上架版本控制.下载地址')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
      this.reset()
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    previewVideoCancel() {
      this.previewVideoVisible = false;
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        type: 1,
        name: null,
        content: null,
        appType: null,
        downloadUrl: null,
        createTime: null,
        remark: null,
      }

    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = this.$t('通用.文本.添加')
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getVersion({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = this.$t('通用.文本.修改')
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          let message = '';
          if (this.form.id !== undefined && this.form.id !== null) {
            message = this.$t('通用.文本.修改成功')
            updateVersion(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            message = this.$t('通用.文本.修改成功')
            addVersion(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
